/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="formRules">
      <b-form>
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="6"
          >
            <!-- Satış Temsilcisi -->
            <b-form-group
              v-if="usersData"
              :label="$t('Satış Temsilcisi')"
              label-for="transfer_user_id"
            >
              <v-select
                id="transfer_user_id"
                v-model="transferUser"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="usersData"
                :reduce="val => val.value"
                :clearable="false"
                input-id="transfer_user_id"
              />
            </b-form-group>

          </b-col>

          <b-col md="6">
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">{{ $t('Kullanıcı Müşterileri') }}</span>
                </b-card-title>
              </b-card-header>
              <b-form-group

                label-for="transfer_user_id"
              >
                <b-form-checkbox
                  v-model="selectAllCustomers"
                  :value="true"
                  :unchecked-value="false"
                >
                  {{ $t('Tümünü Seç') }}
                </b-form-checkbox>
              </b-form-group>

              <b-table
                striped
                responsive
                class="mb-0"
                :items="customersData"
                :fields="customersTableColumns"
              >
                <template #cell(name)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <b-form-checkbox
                    v-model="data.item.selected"
                    :value="true"
                    :unchecked-value="false"
                  />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="startTransfer"
        >
          {{ $t('Aktarımı Başlat') }}
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
// eslint-disable-next-line no-unused-vars
import myUpload from 'vue-image-crop-upload/upload-2.vue'
import {
  required, alphaNum, email,
} from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BFormCheckbox,
    BCardTitle,
    vSelect,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      newPassword: '',
      transferUser: 0,
      selectAllCustomers: true,
    }
  },
  watch: {
    selectAllCustomers(value) {
      if (value) {
        this.customersData.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.selected = true
        })
      } else {
        this.customersData.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.selected = false
        })
      }
    },
  },
  methods: {
    startTransfer() {
      this.$swal({
        title: i18n.t('Emin Misiniz ?'),
        text: i18n.t('Seçilen müşteriler seçmiş olduğunuz satış temsilcisine aktarılacaktır. Bu işlem geri alınamaz.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('Evet'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const customers = []
          this.customersData.forEach(val => {
            if (val.selected) {
              customers.push(val.id)
            }
          })
          store.dispatch('app-user/transferCustomers', { user_id: this.transferUser, customers })
            .then(() => {
              this.successMsg()
            })
        }
      })
    },
    successMsg() {
      this.$bvToast.toast(i18n.t('Successful'), {
        title: i18n.t('Success'),
        toaster: 'b-toaster-bottom-center',
        variant: 'success',
        solid: true,
      })
    },

  },
  setup(props) {
    const usersData = ref(null)
    const customersTableColumns = [{ key: 'name', sortable: false }, { key: 'selected', label: i18n.t('Selected') }]
    const customersData = ref([])
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    store
      .dispatch('app/fetchCustomers', {
        list_type: 'all',
        customer_representative: props.userData.id,
      })
      .then(response => {
        const { customers } = response.data.data
        customers.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.selected = true
        })
        customersData.value = customers
      })
      .catch(() => {
      })

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {

      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      customersData,
      previewEl,
      customersTableColumns,
      inputImageRenderer,
      usersData,
    }
  },
}
</script>
