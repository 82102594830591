/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
<template>
  <div v-if="targetsData">

    <!-- User Info: Input Fields -->
    <validation-observer ref="formRules">
      <b-form>
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="6"
          >
            <!-- Hedef yılı -->
            <b-form-group
              v-if="userData"
              :label="$t('Hedef Yılı')"
              label-for="target_year"
            >

              <b-form-select
                v-model="targetYear"
                :options="targetYears"
                @change="changeYear"
              />
            </b-form-group>
            <b-form-group
              v-if="targetsData"
              :label="$t('Hedef Para Birimi')"
              label-for="currency_type"
            >
              <b-form-select
                v-model="targetsData.currency_id"
                :options="currencyOptions"
              />
            </b-form-group>

          </b-col>

          <b-col md="6">
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">

                  <span class="align-middle ml-50">{{ $t('Hedefler') }}</span>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      v-for="(item, index) in targetsData.targets"
                      :key="index"
                      :label="item.month"
                      :label-for="'target_'+item.month"
                    >
                      <b-form-input
                        :id="'target_'+item.month"
                        v-model="item.value"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveData"
        >
          {{ $t('Kaydet') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BForm, BCard, BCardHeader, BCardTitle, BFormSelect, BFormInput, BCardBody,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
// eslint-disable-next-line no-unused-vars
import myUpload from 'vue-image-crop-upload/upload-2.vue'
import {
  required, alphaNum, email,
} from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCardBody,
    BFormGroup,
    BForm,
    BCard,
    BFormInput,
    BCardHeader,
    BCardTitle,
    ValidationObserver,
    BFormSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      newPassword: '',

      selectAllCustomers: true,
    }
  },
  watch: {
    selectAllCustomers(value) {
      if (value) {
        this.customersData.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.selected = true
        })
      } else {
        this.customersData.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.selected = false
        })
      }
    },
  },
  methods: {
    changeYear() {
      this.fetchTargets()
    },
    saveData() {
      this.targetsData.user_id = this.userData.id
      store.dispatch('app-user/updateTargets', this.targetsData)
        .then(() => {
          this.successMsg()
        })
    },
    successMsg() {
      this.$bvToast.toast(i18n.t('Successful'), {
        title: i18n.t('Success'),
        toaster: 'b-toaster-bottom-center',
        variant: 'success',
        solid: true,
      })
    },

  },
  setup(props) {
    const targetsData = ref(null)
    const targetYear = ref(2021)
    const targetYearStart = ref(2020)
    const targetYears = ref([])
    const d = new Date()
    const thisYear = d.getFullYear()

    const currencyOptions = ref([])
    targetYear.value = thisYear
    const arrTarget = []
    // eslint-disable-next-line no-plusplus
    for (let i = targetYearStart.value; i <= thisYear + 5; i++) {
      arrTarget.push({ text: i, value: i })
    }
    targetYears.value = arrTarget

    const customersTableColumns = [{ key: 'name', sortable: false }, { key: 'selected', label: i18n.t('Selected') }]
    const customersData = ref([])
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({
          text: val.name, value: val.id, is_main: val.is_main, currency: val.currency,
        }))
        currencyOptions.value = arr
      })
    const fetchTargets = () => {
      store
        .dispatch('app-user/fetchTargets', { year: targetYear.value, user_id: props.userData.id })
        .then(response => {
          const { data } = response.data

          targetsData.value = data
        })
        .catch(() => {
        })
    }
    fetchTargets()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {

      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      customersData,
      previewEl,
      fetchTargets,
      customersTableColumns,
      inputImageRenderer,
      targetsData,
      targetYear,
      targetYearStart,
      targetYears,
      currencyOptions,
    }
  },
}
</script>
